import { FeaturedDealer } from 'components/Toolkit/FeaturedDealer/FeaturedDealer';
import { FeaturedAdsProps } from 'features/landing/FeaturedAds/FeaturedAds.typed';

const FeaturedAds = (props: FeaturedAdsProps) => {
  const { cdnUrl, domain, featuredAds } = props;
  return (
    <FeaturedDealer
      heading="Featured Dealer"
      domain={domain}
      cdnUrl={cdnUrl}
      dealerLogo={featuredAds.largeLogoUrl ?? featuredAds.logoUrl}
      title={featuredAds.display}
      listItems={[
        featuredAds.dealerType,
        `Total Stock: ${featuredAds.totalAdCount} Ads`,
      ]}
      showRoomUrl={featuredAds.showroomUrl}
      showRoomText="See Showroom"
      ads={featuredAds.ads}
      testId="featured-dealer-list"
    />
  );
};

export { FeaturedAds };
