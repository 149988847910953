import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';
import type { HomePageValuationModalValues } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';

type HomePageProviderProps = {
  children: ReactNode;
};

type HomePageContext = {
  isValuationModalOpen: boolean;
  setIsValuationModalOpen: (t: boolean) => void;
  homePageValuationModalData?: HomePageValuationModalValues;
  setHomePageValuationModalData: (t: HomePageValuationModalValues) => void;
};

const HomePageContext = createContext<HomePageContext>({
  isValuationModalOpen: false,
  setIsValuationModalOpen: () => null,
  homePageValuationModalData: undefined,
  setHomePageValuationModalData: () => null,
});

const HomePageProvider = ({ children }: HomePageProviderProps) => {
  const [isValuationModalOpen, setIsValuationModalOpen] =
    useState<boolean>(false);
  const [homePageValuationModalData, setHomePageValuationModalData] = useState<
    HomePageValuationModalValues | undefined
  >(undefined);

  return (
    <HomePageContext.Provider
      value={{
        isValuationModalOpen,
        setIsValuationModalOpen,
        homePageValuationModalData,
        setHomePageValuationModalData,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};

const useHomePageContext = () => useContext(HomePageContext);

export { HomePageProvider, useHomePageContext };
