import dynamic from 'next/dynamic';

import * as Styled from 'components/HomePage/styles/HomePage.styled';
import * as HOMEPAGE from 'components/HomePage/helpers/constants';
import SectionListProps from 'components/HomePage/components/SectionBrowser/SectionListProps';
import type { GoFurtherProps } from 'features/landing/GoFurther/GoFurther.typed';

const GoFutherSectionList = dynamic<SectionListProps>(() =>
  import(
    'components/HomePage/components/GoFutherSectionList/GoFurtherSectionList'
  ).then((mod) => mod.GoFutherSectionList),
);

const GoFurther = (props: GoFurtherProps) => {
  const { dashLinks } = props;
  return (
    <Styled.WhiteSection>
      <div className="row small-collapse medium-uncollapse set-width-100">
        <div className="columns small-12">
          <Styled.Container>
            <Styled.SectionHeader>
              <Styled.Heading as="h2">Go Further</Styled.Heading>
            </Styled.SectionHeader>
            <GoFutherSectionList
              sections={HOMEPAGE.GO_FURTHER_OPTIONS}
              dashLinks={dashLinks}
            />
          </Styled.Container>
        </div>
      </div>
    </Styled.WhiteSection>
  );
};

export { GoFurther };
