import { MILEAGE_UNIT } from 'types/index';

type useHomePageValuationLookupProps = {
  accessToken: string | undefined;
};

export enum FORM_VALUES {
  MILEAGE = 'mileage',
  MILEAGE_TYPE = 'mileageType',
  REGISTRATION = 'registration',
}

type ValuationLookupFormValues = {
  mileage: string;
  mileageType: MILEAGE_UNIT.MILES | MILEAGE_UNIT.KILOMETRES;
  registration: string;
};

type HomePageValuationModalValues =
  | {
      mileage: string;
      mileageType: string;
      registration: string;
      make?: string;
      model: string;
      year: string;
      trim: string;
      bodyType: string;
      fuelType: string;
      transmission: string;
      valuation: {
        min: number;
        max: number;
      };
    }
  | undefined;

export enum HOMEPAGE_VALUATION_LOOKUP {
  KEY = 'HomepageValuationData',
}

export type {
  useHomePageValuationLookupProps,
  ValuationLookupFormValues,
  HomePageValuationModalValues,
};
