import type { AxiosResponse } from 'axios';
import { donedealGateway } from 'api/api-utils/commonApiUtils';
import type {
  CarValuationResponse,
  DealerCarValuationResponse,
  MileageIndicatorApiResponse,
  MileageIndicatorRequest,
} from 'api/types/CarValuationApiTypes';
import type { IncomingHttpHeaders } from 'http';

async function postCarMileageIndicator(
  payload: MileageIndicatorRequest,
  headers?: IncomingHttpHeaders,
): Promise<AxiosResponse<MileageIndicatorApiResponse>> {
  return await donedealGateway.post(
    `/ddapi/v1/vehicle/valuate/mileage-indicator`,
    payload,
    {
      headers: {
        ...headers,
        accept: 'application/json',
      },
    },
  );
}

async function getCarValuation(
  registrationNumber: string,
  mileageInKilometers: string,
  source: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<CarValuationResponse>> {
  const response: AxiosResponse<CarValuationResponse> =
    await donedealGateway.get(
      `/ddapi/v1/vehicle/valuate/${registrationNumber}?mileageInKilometers=${mileageInKilometers}&source=${source}`,
      {
        headers: {
          ...headers,
          accept: 'application/json',
        },
      },
    );

  return response;
}

async function getDealerCarValuation(
  registrationNumber: string,
  mileageInKilometers: string,
  source: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<DealerCarValuationResponse>> {
  const response: AxiosResponse<DealerCarValuationResponse> =
    await donedealGateway.get(
      `/dhapi/v1/vehicle/valuate/${registrationNumber}?mileageInKilometers=${mileageInKilometers}&source=${source}`,
      {
        headers: {
          ...headers,
          accept: 'application/json',
        },
      },
    );

  return response;
}

export const carValuationApi = {
  getCarValuation,
  getDealerCarValuation,
  postCarMileageIndicator,
};
