import { ErrorBoundary } from 'react-error-boundary';
import type { ReactNode } from 'react';
import { Notification } from 'components/Toolkit/Notification/Notification';

function ErrorFallback({ error }: { error: any }) {
  console.log(error);
  return (
    <Notification intent="PLAIN">
      Oops, something went wrong loading this part of the page. Please try again
      later"
    </Notification>
  );
}

function SimpleErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}

export { SimpleErrorBoundary, ErrorFallback };
