import NextLink from 'next/link';

import type { RecentAdsProps } from 'features/landing/RecentAds/RecentAds.typed';

import { SimpleErrorBoundary } from 'helpers/SimpleErrorBoundary';
import convertToFriendlyPath from 'utils/FriendlyPath';
import { accessibleOnClick } from 'utils/Accessibility';
import { useCampaignURLHandler } from 'components/PageWrappers/Hooks/Campaigns/UseCampaignURLHandler';

import { RecentAdsCard } from 'features/landing/RecentAds/RecentAdsCard';
import { Link } from 'components/Toolkit/Button/Link';

import {
  Container,
  HeaderContainer,
  AdGrid,
  Heading,
} from 'features/landing/RecentAds/RecentAds.styled';

const RecentAds = (props: RecentAdsProps) => {
  const { domain, recentAds, recentAdsUrl } = props;

  const { handleCampaignAdRouterPush } = useCampaignURLHandler();

  return (
    <div className="row small-collapse medium-uncollapse set-width-100">
      <div className="columns small-12">
        <Container>
          <HeaderContainer>
            <Heading>Recent Ads</Heading>
            {recentAdsUrl && (
              <Link
                href={recentAdsUrl}
                ofType="SECONDARY"
                underline
                NextLink={NextLink}
              >
                See All
              </Link>
            )}
          </HeaderContainer>
          <SimpleErrorBoundary>
            <AdGrid data-testid="recent-ads-list">
              {recentAds.map((ad) => {
                const { id, friendlyUrl } = ad;

                const friendlyPath = convertToFriendlyPath(
                  domain,
                  friendlyUrl!,
                );

                return (
                  <li
                    key={id}
                    {...accessibleOnClick(
                      () => handleCampaignAdRouterPush(friendlyPath),
                      'link',
                    )}
                  >
                    <RecentAdsCard {...ad} />
                  </li>
                );
              })}
            </AdGrid>
          </SimpleErrorBoundary>
        </Container>
      </div>
    </div>
  );
};

export { RecentAds };
