import type { ReactNode } from 'react';
import styled from 'styled-components';
import Head from 'next/head';

import { media } from 'helpers/breakpoints';
import { withOpacity } from 'helpers/Theme/Theme';

export interface HeroImageProps {
  section: string;
  cdnUrl: string;
  children?: ReactNode;
}

type ContainerProps = { section: string; cdnUrl: string };
const Container = styled.div<ContainerProps>`
  position: relative;
  height: 432px;

  background: ${({ cdnUrl, section }) =>
    `#000 url('${cdnUrl}/images/hero/home/blurred/${section}-small-blur@1x.jpeg') center`};
  background-size: cover;

  ${media.medium} {
    height: 400px;
    background-image: ${({ cdnUrl, section }) =>
      `url('${cdnUrl}/images/hero/home/blurred/${section}-medium-blur@1x.jpeg')`};
  }

  ${media.large} {
    background-image: ${({ cdnUrl, section }) =>
      `url('${cdnUrl}/images/hero/home/blurred/${section}-full-blur@1x.jpeg')`};
  }
`;

type HighResProps = { section: string; cdnUrl: string };
const HighRes = styled.div<HighResProps>`
  ${media.smallOnly} {
    background-position: ${({ section }) =>
      section === 'farming' || section === 'motor' ? 'right' : ''};
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background: ${({ cdnUrl, section }) =>
    `transparent url('${cdnUrl}/images/hero/home/regular/${section}-small@2x.jpeg') center`};
  background-size: cover;

  ${media.medium} {
    background-image: ${({ cdnUrl, section }) =>
      `url('${cdnUrl}/images/hero/home/regular/${section}-medium@2x.jpeg')`};
    background-position: ${({ section }) => section === 'motor' && 'right'};
  }

  ${media.large} {
    background-image: ${({ cdnUrl, section }) =>
      `url('${cdnUrl}/images/hero/full/${section}-full@2x.jpeg')`};
    background-position: ${({ section }) =>
      section === 'farming' ? 'bottom' : 'center'};
  }
`;

type OverlayProps = {
  section: string;
};
const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, section }) =>
    `${
      section === 'motor'
        ? withOpacity(theme.colors.GREY_DARKER, 0.32)
        : withOpacity(theme.colors.BLACK)
    }`};
  padding-top: 104px;

  ${media.smallOnly} {
    padding-top: 80px;
  }
`;

const heroImagesToPreload: [string, string][] = [
  [
    '/images/hero/home/blurred/motor-small-blur@1x.jpeg',
    '(max-width: 39.9375em)',
  ],
  [
    '/images/hero/home/blurred/motor-medium-blur@1x.jpeg',
    '(min-width: 40em) and (max-width: 63.9375em)',
  ],
  ['/images/hero/home/blurred/motor-full-blur@1x.jpeg', '(min-width: 64em)'],
  [
    '/images/hero/home/blurred/marketplace-small-blur@1x.jpeg',
    '(max-width: 39.9375em)',
  ],
  [
    '/images/hero/home/blurred/marketplace-medium-blur@1x.jpeg',
    '(min-width: 40em) and (max-width: 63.9375em)',
  ],
  [
    '/images/hero/home/blurred/marketplace-full-blur@1x.jpeg',
    '(min-width: 64em)',
  ],
  [
    '/images/hero/home/blurred/farming-small-blur@1x.jpeg',
    '(max-width: 39.9375em)',
  ],
  [
    '/images/hero/home/blurred/farming-medium-blur@1x.jpeg',
    '(min-width: 40em) and (max-width: 63.9375em)',
  ],
  ['/images/hero/home/blurred/farming-full-blur@1x.jpeg', '(min-width: 64em)'],
];

function HeroImage({ children, cdnUrl, section }: HeroImageProps) {
  return (
    <>
      <Head>
        {heroImagesToPreload.map(([href, media], index) => (
          <link
            key={index}
            rel="preload"
            href={`${cdnUrl}${href}`}
            as="image"
            media={media}
          />
        ))}
      </Head>
      <Container section={section.toLowerCase()} cdnUrl={cdnUrl}>
        <HighRes section={section.toLowerCase()} cdnUrl={cdnUrl} />
        <Overlay section={section}>{children}</Overlay>
      </Container>
    </>
  );
}

export { HeroImage };
