import styled from 'styled-components';

import { media } from 'helpers/breakpoints';
import { InfoListItem } from 'components/Toolkit/InfoListItem/InfoListItem';
import type { InfoListItemProps } from 'components/Toolkit/InfoListItem/InfoListItem';

export interface DetailListProps {
  singleColumnOnDesktop?: boolean;
  list: InfoListItemProps[];
  className?: string;
  onClick?: () => void;
}

const Container = styled.div<{
  singleColumnOnDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    grid-template-columns: ${({ singleColumnOnDesktop }) =>
      singleColumnOnDesktop ? '1fr' : '1fr 1fr'};
    grid-column-gap: ${({ theme }) => theme.spacing.L56};
  }
`;

function DetailList({
  singleColumnOnDesktop = false,
  list,
  className,
  onClick,
}: DetailListProps) {
  return (
    <Container
      onClick={onClick}
      singleColumnOnDesktop={singleColumnOnDesktop}
      className={className}
    >
      {list.map((item, index) => {
        return (
          <InfoListItem
            key={index}
            icon={item.icon}
            title={item.title}
            body={item.body}
          />
        );
      })}
    </Container>
  );
}

export { DetailList };
