import styled from 'styled-components';
import { WithLink } from 'components/ToolkitV2/CardV2/enhancements/WithLink/WithLink';
import { media } from 'helpers/breakpoints';

const WrapperLifeStyleItem = styled(WithLink)`
  background: ${({ theme }) => theme.colors.OFFWHITE};
  padding: ${({ theme }) => theme.spacing.M16};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const Title = styled.p`
  display: flex;
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  color: ${({ theme }) => theme.colors.GREY_DARKER};
  justify-content: center;
`;

const Counter = styled.p`
  display: flex;
  ${({ theme }) => theme.fontSize.M14};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  justify-content: center;
`;

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.M16} 0;

  ${media.medium} {
    padding: 0;
  }

  ${media.large} {
    margin: 0 0 ${({ theme }) => theme.spacing.M32} 0;
  }
`;

export { Wrapper, WrapperLifeStyleItem, Title, Counter };
