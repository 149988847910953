import { useState } from 'react';
import { useRouter } from 'next/router';
import { StrictOption } from 'components/Toolkit/Inputs/Select';
import { useHomePageContext } from 'contexts/HomePageContext';
import { formatPriceRange } from 'helpers/formatting';
import { useQueryParams } from 'hooks/UseQueryParams';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { useMyVehicleUpdate } from 'components/CarValuationPage/hooks/useMyVehicleUpdate';
import { convertMilesToKm } from 'helpers/formatting';
import { fireToast } from 'helpers/Toasts';
import { MILEAGE_UNIT } from 'types/index';
import { generateSimilarCarsLink } from 'helpers/Links/Links';
import { SortOptions, FilterOptions } from 'helpers/Links/Links.typed';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

const renewalOptions: StrictOption[] = [
  {
    label: 'I do not have a renewal',
    value: '',
  },
  {
    label: 'January',
    value: '1',
  },
  {
    label: 'February',
    value: '2',
  },
  {
    label: 'March',
    value: '3',
  },
  {
    label: 'April',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'June',
    value: '6',
  },
  {
    label: 'July',
    value: '7',
  },
  {
    label: 'August',
    value: '8',
  },
  {
    label: 'September',
    value: '9',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];

const useValuationModal = () => {
  const { setIsValuationModalOpen, homePageValuationModalData } =
    useHomePageContext();

  const {
    make,
    model,
    year,
    bodyType,
    fuelType,
    transmission,
    trim,
    registration,
    mileage,
    mileageType,
    valuation,
  } = homePageValuationModalData || {
    make: '',
    model: '',
    year: '',
    trim: '',
    bodyType: '',
    fuelType: '',
    transmission: '',
    registration: '',
    mileage: '',
    mileageType: '',
    valuation: { min: 0, max: 0 },
  };

  const { updateMyVehicle } = useMyVehicleUpdate({
    mileage:
      mileageType === MILEAGE_UNIT.MILES
        ? convertMilesToKm(Number(mileage))
        : mileage,
    registration,
    source: 'HOMEPAGE',
  });

  const { checkIfQueryIsPresent } = useQueryParams();
  const { asPath } = useRouter();
  const [insuranceRenewalMonth, setInsuranceRenewalMonth] = useState('');
  const { handleLogin } = useAuthOptions();

  const MODAL_QUERY_KEY = 'modal';
  const MODAL_QUERY_VALUE = 'homepage-valuation';
  const isHomePageValuationModalQueryPresent = checkIfQueryIsPresent(
    MODAL_QUERY_KEY,
    MODAL_QUERY_VALUE,
  );
  const callbackUrl = isHomePageValuationModalQueryPresent
    ? asPath
    : `${asPath}&modal=${MODAL_QUERY_VALUE}`;

  // TODO: It would be good to move the valuation work accross the app into a domain / repository.

  const mileageUnit = mileageType === MILEAGE_UNIT.KILOMETRES ? 'kms' : 'miles';
  const vehicleInfoTitle = {
    title: `${make} ${model} ${year}`,
    subtitle: [registration, trim, `${mileage}${mileageUnit}`],
  };

  const similarCarsLink = generateSimilarCarsLink(
    make,
    model,
    year,
    SortOptions.LEAST_EXPENSIVE,
    {
      sellerType: FilterOptions.PRIVATE_CARS,
      fuelType,
      transmission,
      bodyType,
    },
  );

  const valuationRange = formatPriceRange({
    min: valuation?.min,
    max: valuation?.max,
  });

  const closeModal = () => {
    setIsValuationModalOpen(false);
  };

  const directUserToSigninPage = () => {
    handleLogin(callbackUrl);
  };

  const openModal = () => {
    setIsValuationModalOpen(true);
  };

  const handleRenewalMonthChange = (month: string) => {
    const renewalMonth = renewalOptions.find(
      (option) => option.value === month,
    );

    if (renewalMonth) {
      setInsuranceRenewalMonth(renewalMonth.label);
    }
  };

  const handleSaveProfile = () => {
    try {
      updateMyVehicle(insuranceRenewalMonth);
      closeModal();
      fireToast({
        type: 'SUCCESS',
        text: 'Your changes have been saved to your profile',
      });
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling handleSaveProfile'),
        tags: [PAGE.HOME],
        customData: {
          message: error.message || 'client_error',
        },
      });
      fireToast({
        type: 'ERROR',
        text: 'Something went wrong, please try again',
      });
    }
  };

  return {
    renewalOptions,
    vehicleInfoTitle,
    similarCarsLink,
    valuationRange,
    closeModal,
    handleRenewalMonthChange,
    handleSaveProfile,
    openModal,
    directUserToSigninPage,
  };
};

export { useValuationModal };
