import { useRouter } from 'next/router';
import { usePathname, useSearchParams } from 'next/navigation';
import { z } from 'zod';

function useQueryParams() {
  const { push, query } = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const addQueries = (additionalParams: { [key: string]: string }) => {
    push({ query: { ...query, ...additionalParams } }, undefined, {
      shallow: true,
    });
  };

  const addQueriesKeepPath = (additionalParams: { [key: string]: string }) => {
    const newParams = new URLSearchParams(searchParams?.toString());
    Object.entries(additionalParams).forEach(([key, value]) => {
      newParams.set(key, value);
    });
    const updatedParams = newParams ? `?${newParams}` : '';
    push(`${pathname}${updatedParams}`, undefined, {
      shallow: true,
    });
  };

  const removeQueries = (queryKeys: string[]) => {
    const filteredObject = Object.fromEntries(
      Object.entries(query).filter(([key]) => !queryKeys.includes(key)),
    );
    push({ query: filteredObject }, undefined, {
      shallow: true,
    });
  };

  const removeQueriesKeepPath = (queryKeys: string[]) => {
    if (!searchParams) return;
    const currentParams = new URLSearchParams(searchParams);
    queryKeys.forEach((key) => {
      currentParams.delete(key);
    });
    const newParams = currentParams.toString();
    push(`${pathname}${newParams ? `?${newParams}` : ''}`, undefined, {
      shallow: true,
    });
  };

  const checkIfQueryIsPresent = (queryKey: string, queryValue: string) => {
    return query[queryKey] === queryValue;
  };

  const querySchema = z
    .object({
      query: z.record(z.union([z.string(), z.array(z.string())])),
      queryKey: z.string(),
      queryValue: z.string(),
    })
    .refine(
      (data) => {
        const value = data.query[data.queryKey];
        if (value && Array.isArray(value)) {
          return value.includes(data.queryValue);
        } else {
          return value === data.queryValue;
        }
      },
      {
        message: 'No query value found',
      },
    );

  const hasQueryValue = (queryKey: string, queryValue: string) =>
    querySchema.safeParse({ query, queryKey, queryValue }).success;

  const getQueryValue = (queryKey: string) => {
    return query[queryKey];
  };

  return {
    addQueries,
    removeQueries,
    checkIfQueryIsPresent,
    hasQueryValue,
    addQueriesKeepPath,
    removeQueriesKeepPath,
    getQueryValue,
  };
}

export { useQueryParams };
