import dynamic from 'next/dynamic';

import * as Styled from 'components/HomePage/styles/HomePage.styled';
import { SectionsProps } from 'features/landing/Sections/Sections.typed';
import SectionListProps from 'components/HomePage/components/SectionBrowser/SectionListProps';

const FarmingSectionsList = dynamic<SectionListProps>(() =>
  import(
    'components/HomePage/components/SectionBrowser/FarmingSectionsList'
  ).then((mod) => mod.FarmingSectionsList),
);

const MotorSectionsList = dynamic<SectionListProps>(() =>
  import(
    'components/HomePage/components/SectionBrowser/MotorSectionsList'
  ).then((mod) => mod.MotorSectionsList),
);

const MarketplaceSectionsList = dynamic<SectionListProps>(() =>
  import(
    'components/HomePage/components/SectionBrowser/MarketPlaceSectionsList'
  ).then((mod) => mod.MarketplaceSectionsList),
);

const Sections = (props: SectionsProps) => {
  const { h1, siteFocus, sections, dashLinks } = props;
  return (
    <>
      <Styled.SectionHeader>
        <Styled.Heading as="h1">{h1}</Styled.Heading>
      </Styled.SectionHeader>
      {siteFocus === 'farming' && (
        <FarmingSectionsList sections={sections} dashLinks={dashLinks} />
      )}

      {siteFocus === 'marketplace' && (
        <MarketplaceSectionsList sections={sections} dashLinks={dashLinks} />
      )}
      {siteFocus === 'motor' && (
        <MotorSectionsList sections={sections} dashLinks={dashLinks} />
      )}
    </>
  );
};

export { Sections };
