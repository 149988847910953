import type { Tab } from 'components/Toolkit/TabSelector/TabSelector';
import type { SiteFocus } from 'components/HomePage/types';

export const TABS: Tab<SiteFocus>[] = [
  {
    label: 'Motors',
    value: 'motor',
  },
  {
    label: 'Marketplace',
    value: 'marketplace',
  },
  {
    label: 'Farming',
    value: 'farming',
  },
];

export const GO_FURTHER_OPTIONS = [
  {
    name: 'find-a-dealer',
    displayName: 'Find a Dealer',
  },
  {
    name: 'instant-offer',
    displayName: 'Sell My Car to a Dealer',
  },
  {
    name: 'sell-my-car',
    displayName: 'How to Sell My Car',
  },
  { name: 'value-my-car', displayName: 'Value My Car' },
  {
    name: 'greenlight-history-check',
    displayName: 'Vehicle History Check',
  },
  {
    name: 'buying-guide',
    displayName: 'Buying Guide',
    link: 'https://hello.donedeal.ie/hc/en-us/sections/201282821',
  },
  {
    name: 'electric-cars',
    displayName: 'Electric Vehicle Hub',
  },
  {
    name: 'blog',
    displayName: 'View our Blog',
    link: 'https://blog.donedeal.ie',
  },
];

export const LAST_SEARCH_DATA_TRACKING_VALUE = 'last_search_home_page';
