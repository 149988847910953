import { DFPUnit } from 'components/Toolkit/DFP/DFP';
import { HOME_PAGE_DFP_CONFIG } from 'configs/dfpConfig';
import * as Styled from 'components/HomePage/styles/HomePage.styled';
import type { TopAdUnitProps } from 'features/landing/TopAdUnit/TopAdUnit.typed';
import { useReducer } from 'react';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

const TopAdUnit = (props: TopAdUnitProps) => {
  const { siteFocus } = props;
  const [adUnitKey, newAdUnitKey] = useReducer((key) => key + 1, 0);
  const adUnitClass = 'common-margin-bottom';
  useOnUpdateOnly(() => {
    newAdUnitKey();
  }, [siteFocus]);

  const adUnits = {
    motor: (
      <DFPUnit
        key={adUnitKey}
        className={adUnitClass}
        {...HOME_PAGE_DFP_CONFIG.dd_homepage_top_motor}
      />
    ),
    marketplace: (
      <DFPUnit
        key={adUnitKey + 1}
        className={adUnitClass}
        {...HOME_PAGE_DFP_CONFIG.dd_homepage_top_marketplace}
      />
    ),
    farming: (
      <DFPUnit
        key={adUnitKey + 2}
        className={adUnitClass}
        {...HOME_PAGE_DFP_CONFIG.dd_homepage_top_farming}
      />
    ),
  };

  return (
    <Styled.HomePageUnit
      backgroundColor={siteFocus === 'motor' ? 'WHITE' : 'OFFWHITE'}
    >
      <Styled.UnitContainer>{adUnits[siteFocus]}</Styled.UnitContainer>
    </Styled.HomePageUnit>
  );
};

export { TopAdUnit };
