import type { ReactNode } from 'react';

import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { SecondarySelectProps } from 'features/location/components/SecondarySelect/SecondarySelect.typed';
import { PAGE } from 'helpers/pages';
import { HeightVariant } from 'components/Toolkit/Inputs/types';

import type { DisplayContainerConfig } from 'components/Support/DisplayContainer/DisplayContainer';

export interface AreasResponse {
  id: number;
  countyTown: string;
  latitude: number;
  longitude: number;
}

export type Area = {
  countyTown: string;
  county: string;
  type: 'TOWN' | 'COUNTY';
  displayName: string;
  latitude: number;
  longitude: number;
};

export type Coordinates = {
  latitude: string;
  longitude: string;
};

export type CountyData = {
  displayName: string;
  value: string;
};

export enum LEVELS {
  LEVEL_ONE = 'LEVEL_ONE', // Counties
  LEVEL_TWO = 'LEVEL_TWO', // Counties with Areas
}

export enum ACTION_TYPE {
  SET_AREAS = 'SET_AREAS',
  SET_SELECTED_COUNTY = 'SET_SELECTED_COUNTY',
  SET_LOCATION_DISPLAY = 'SET_LOCATION_DISPLAY',
  SET_IS_EXPANDED = 'SET_IS_EXPANDED',
  SET_SEARCH_INPUT = 'SET_SEARCH_INPUT',
  SET_RADIUS = 'SET_RADIUS',
  SET_IS_LOCATION_BOTTOM_SHEET_OPEN = 'SET_IS_LOCATION_BOTTOM_SHEET_OPEN',
  SET_COORDINATES = 'SET_COORDINATES',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_LEVEL = 'SET_LEVEL',
  LEVEL_ONE_RESET = 'LEVEL_ONE_RESET',
  LEVEL_TWO_RESET = 'LEVEL_TWO_RESET',
  SET_COUNTY_AREAS = 'SET_COUNTY_AREAS',
  COUNTY_AREA_SELECT = 'COUNTY_AREA_SELECT',
  HANDLE_CLOSE = 'HANDLE_CLOSE',
  SET_AUTOCOMPLETE_OPTIONS = 'SET_AUTOCOMPLETE_OPTIONS',
  SET_COUNTY_TOWN = 'SET_COUNTY_TOWN',
  RESET_TO_DEFAULT = 'RESET_TO_DEFAULT',
  CLOSE_DISPLAY = 'CLOSE_DISPLAY',
}

export type StateType = {
  areas: ListItem[];
  selectedCounty: ListItem | null;
  locationDisplay: string;
  isExpanded: boolean;
  searchInput: string;
  radius: string;
  isLocationBottomSheetOpen: boolean;
  coordinates: Coordinates;
  isLoading: boolean;
  level: LEVELS;
  countyTown: string | null;
};

type SetAreasType = {
  type: ACTION_TYPE.SET_AREAS;
  payload: ListItem[];
};

type SetSelectedCountyType = {
  type: ACTION_TYPE.SET_SELECTED_COUNTY;
  payload: ListItem | null;
};

type SetLocationDisplayType = {
  type: ACTION_TYPE.SET_LOCATION_DISPLAY;
  payload: string;
};

type SetIsExpandedType = {
  type: ACTION_TYPE.SET_IS_EXPANDED;
  payload: boolean;
};

type SetSearchInputType = {
  type: ACTION_TYPE.SET_SEARCH_INPUT;
  payload: string;
};

type SetRadiusType = {
  type: ACTION_TYPE.SET_RADIUS;
  payload: string;
};

type SetIsLocationBottomSheetOpenType = {
  type: ACTION_TYPE.SET_IS_LOCATION_BOTTOM_SHEET_OPEN;
  payload: boolean;
};

type SetCoordinatesType = {
  type: ACTION_TYPE.SET_COORDINATES;
  payload: Coordinates;
};

type SetIsLoadingType = {
  type: ACTION_TYPE.SET_IS_LOADING;
  payload: boolean;
};

type SetLevelType = {
  type: ACTION_TYPE.SET_LEVEL;
  payload: LEVELS;
};

type SetCountyTownType = {
  type: ACTION_TYPE.SET_COUNTY_TOWN;
  payload: string;
};

type LevelOneResetType = {
  type: ACTION_TYPE.LEVEL_ONE_RESET;
  payload: {
    areas: ListItem[];
    radius: string;
  };
};

type LevelTwoResetType = {
  type: ACTION_TYPE.LEVEL_TWO_RESET;
  payload: ListItem[];
};

type SetCountyAreasType = {
  type: ACTION_TYPE.SET_COUNTY_AREAS;
  payload: {
    selectedCounty: ListItem | null;
    locationDisplay: string;
    areas: ListItem[];
    radius: string;
    countyTown: string | null;
  };
};

type CountyAreaSelectType = {
  type: ACTION_TYPE.COUNTY_AREA_SELECT;
  payload: {
    locationDisplay: string;
    coordinates: Coordinates;
    selectedCounty: ListItem;
    countyTown: string | null;
    radius: string;
  };
};

type SetAutoCompleteOptionsType = {
  type: ACTION_TYPE.SET_AUTOCOMPLETE_OPTIONS;
  payload: ListItem[];
};

type HandleCloseType = {
  type: ACTION_TYPE.HANDLE_CLOSE;
  payload: ListItem[];
};

type ResetToDefaultType = {
  type: ACTION_TYPE.RESET_TO_DEFAULT;
  payload: {
    areas: ListItem[];
    radius: string;
  };
};

type CloseDisplay = {
  type: ACTION_TYPE.CLOSE_DISPLAY;
};

export type ActionType =
  | SetAreasType
  | SetSelectedCountyType
  | SetIsExpandedType
  | SetIsLoadingType
  | SetLocationDisplayType
  | SetSearchInputType
  | SetRadiusType
  | SetIsLocationBottomSheetOpenType
  | SetCoordinatesType
  | SetLevelType
  | LevelOneResetType
  | LevelTwoResetType
  | SetCountyAreasType
  | CountyAreaSelectType
  | HandleCloseType
  | SetAutoCompleteOptionsType
  | SetCountyTownType
  | ResetToDefaultType
  | CloseDisplay;

export interface LocationProps extends SecondarySelectProps {
  secondarySelectWidth?: string;
  maxHeight?: string;
  maxTextContainerWidthMWeb?: string;
  maxTextContainerWidthWeb?: string;
  skeletonListLength: number;
  onSelect: (value: CountyData) => void;
  bottomSheetConfig: DisplayContainerConfig;
  dropdownConfig: DisplayContainerConfig;
  heightVariant?: HeightVariant;
  radiusCallback?: (radius: string) => void;
}

export type ContainerStyleProps = {
  displayInline?: boolean;
};

export type SecondarySelectContainerStyleProps = {
  secondarySelectWidth?: string;
};

export interface LocationProviderProps {
  children: ReactNode;
  initialSelectedCounty?: ListItem | null;
  initialRadius?: string | null;
  initialLocationDisplay?: string;
  initialCoordinates?: Coordinates;
  initialCountyTown?: string | null;
  countyList: {
    displayName: string;
    value: string;
  }[];
  radiusList: {
    label: string;
    value: string;
  }[];
  page?: PAGE | null;
}

export interface UseLocationReducerProps {
  initialSelectedCounty: ListItem | null;
  initialRadius: string | null;
  initialLocationDisplay: string;
  initialCoordinates: Coordinates;
  initialCountyTown: string | null;
  countyList: {
    displayName: string;
    value: string;
  }[];
  radiusList?: {
    label: string;
    value: string;
  }[];
}

export interface ILocationContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  isLocationBottomSheetOpen: boolean;
  setIsLocationBottomSheetOpen: (isLocationBottomSheetOpen: boolean) => void;
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  locationDisplay: string;
  setLocationDisplay: (locationDisplay: string) => void;
  areas: ListItem[];
  setAreas: (areas: ListItem[]) => void;
  coordinates: Coordinates;
  setCoordinates: (coordinates: Coordinates) => void;
  radius: string;
  setRadius: (radius: string) => void;
  selectedCounty: ListItem | null;
  setSelectedCounty: (selectedCounty: ListItem | null) => void;
  level: LEVELS;
  setLevel: (level: LEVELS) => void;
  countyTown: string | null;
  setCountyTown: (countyTown: string | null) => void;
  levelOneReset: () => void;
  levelTwoReset: (areas: ListItem[]) => void;
  setAutocompleteOptions: (areas: ListItem[]) => void;
  setCountyAreas: (
    selectedCounty: ListItem | null,
    locationDisplay: string,
    areas: ListItem[],
    countyTown: string | null,
  ) => void;
  countyAreaSelect: (
    locationDisplay: string,
    coordinates: Coordinates,
    selectedCounty: ListItem,
    countyTown: string | null,
    radius?: string,
  ) => void;
  handleClose: (areas: ListItem[]) => void;
  resetToDefault: () => void;
  countyList: {
    displayName: string;
    value: string;
  }[];
  radiusList: {
    label: string;
    value: string;
  }[];
  page?: PAGE | null;
  closeDisplay: () => void;
}
