import { Field } from 'react-final-form';
import styled from 'styled-components';
import {
  InputWithIcon,
  Container as InputContainer,
} from 'components/Toolkit/Inputs/InputWithIcon';

import {
  TextBoxWithUnit,
  Input,
  SSelect,
} from 'components/Toolkit/PublishFilters/TextBoxWithUnit/TextBoxWithUnit';
import { media } from 'helpers/breakpoints';

import {
  handleErrorMessage,
  formatFormNumber,
  removeNonNumericCharacters,
} from 'helpers/forms';
import { required, composeValidators } from 'helpers/InputValidators';
import { MILEAGE_UNIT } from 'types';

const SInputWithIcon = styled(InputWithIcon)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${InputContainer} {
    grid-template-columns: 1fr;
  }

  label {
    ${({ theme }) => theme.fontSize.M16};
    color: ${({ theme }) => theme.colors.GREY_DARK};
    margin-bottom: ${({ theme }) => theme.spacing.S8};
  }

  ${media.large} {
    flex: 50%;
    margin-right: ${({ theme }) => theme.spacing.M16};
    margin-bottom: 0;
  }
`;

const STextBoxWithUnit = styled(TextBoxWithUnit)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${Input} {
    ${media.large} {
      width: 188px;
    }
  }

  ${SSelect} {
    ${media.large} {
      width: 94px;
    }
  }

  label {
    ${({ theme }) => theme.fontSize.M16};
    color: ${({ theme }) => theme.colors.GREY_DARK};
    margin-bottom: ${({ theme }) => theme.spacing.S8};
  }

  ${media.large} {
    flex: 50%;
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  ${media.large} {
    width: 100%;
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const mileageOptions = [
  { label: 'km', value: MILEAGE_UNIT.KILOMETRES },
  { label: 'miles', value: MILEAGE_UNIT.MILES },
];

type TValuationTool = {
  cdnUrl: string;
};

function ValuationToolFields({ cdnUrl }: TValuationTool) {
  return (
    <Container>
      <Field<string>
        name="registration"
        validate={composeValidators(required('Registration Required'))}
      >
        {({ input, meta }) => (
          <SInputWithIcon
            icon={`${cdnUrl}/images/illustrations/eu-symbol-v2.svg`}
            label={'Registration Number'}
            name={input.name}
            onChange={input.onChange}
            placeholder={'e.g 191WX1234'}
            value={input.value}
            willUseSubText={false}
            willUseButton={false}
            errorMessage={handleErrorMessage(meta)}
          />
        )}
      </Field>

      <STextBoxWithUnit
        displayName="Odometer reading"
        flip
        initialUnitValue={MILEAGE_UNIT.KILOMETRES}
        options={mileageOptions}
        unitName="mileageType"
        valueName="mileage"
        placeholder="e.g 45,000"
        willUseSubText={false}
        formatFunction={formatFormNumber}
        parseFunction={removeNonNumericCharacters}
        optional={false}
      />
    </Container>
  );
}

export { ValuationToolFields };
