import styled from 'styled-components';
import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { media } from 'helpers/breakpoints';

const Content = styled.div`
  width: 100%;

  ${media.medium} {
    width: 544px;
  }
`;

const SubtitleContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.S8};
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin-right: ${({ theme }) => theme.spacing.S4};
`;

const Header = styled(InfoTitle)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const VehicleValuationPanel = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  padding: ${({ theme }) => theme.spacing.M16};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  a {
    ${({ theme }) => theme.fontSize.M14};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-transform: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  ${media.medium} {
    flex-direction: row;

    a {
      justify-content: flex-end;
    }
  }
`;

const Space = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

const RenewalPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.spacing.M16};

  label,
  li {
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }
`;

const RenewalInfoTitle = styled(InfoTitle)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

// Error Styles

const DetailListHeading = styled.div`
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

export {
  Content,
  Header,
  SubtitleContainer,
  Subtitle,
  VehicleValuationPanel,
  Space,
  RenewalPanel,
  RenewalInfoTitle,
  DetailListHeading,
};
