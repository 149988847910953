import styled from 'styled-components';

import { media } from 'helpers/breakpoints';
import { GhostButton } from 'components/Toolkit/Button';

const Container = styled.div`
  overflow-x: hidden;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const Title = styled.p`
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  flex-direction: column;

  ${media.medium} {
    flex-direction: row;
    align-items: center;
  }
`;

const NavigationButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.S8};
`;

const NavigationButton = styled(GhostButton)`
  display: none;
  width: ${({ theme }) => theme.spacing.M32};
  height: ${({ theme }) => theme.spacing.M32};
  min-width: ${({ theme }) => theme.spacing.M32};

  ${media.medium} {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: ${({ theme }) => theme.colors.GREY_DARKER};
    padding: ${({ theme }) => theme.spacing.S4};
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: ${({ theme }) => theme.colors.GREY_DARKER};
    }

    &:disabled {
      fill: ${({ theme }) => theme.colors.GREY};
      cursor: not-allowed;
    }
  }
`;

const Carousel = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;
  display: flex;
  gap: ${({ theme }) => theme.spacing.S8};
  padding: ${({ theme }) => theme.spacing.S4};
`;

export {
  Container,
  Heading,
  Title,
  NavigationButtonContainer,
  NavigationButton,
  Carousel,
};
