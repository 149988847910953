import { useRef, useState } from 'react';

import type { useCarouselArgs } from 'features/carousel/Carousel.hook.typed';

// Inspired by: https://jcxmpbxll.medium.com/creating-a-carousel-with-horizontal-scrolling-using-react-19efbf67b47d
function useCarousel(props: useCarouselArgs) {
  const { scrollDistance } = props;

  const [positionStatuses, setPositionStatuses] = useState({
    isAtStartPosition: true,
    isAtEndPosition: false,
  });

  const carouselRef = useRef<HTMLDivElement>(null);

  // On call of "moveLeft" or "moveRight" component rerenders and constant is set to new position
  const xAxisPosition = carouselRef.current?.scrollLeft || 0;

  const totalScrollDistance =
    (carouselRef.current?.scrollWidth || 0) -
    (carouselRef.current?.clientWidth || 0);

  const moveLeft = () => {
    if (xAxisPosition - scrollDistance >= 0) {
      carouselRef.current?.scrollTo(
        carouselRef.current?.scrollLeft - scrollDistance,
        0,
      );
    } else {
      carouselRef.current?.scrollTo(0, 0);
    }
  };

  const moveRight = () => {
    if (xAxisPosition + scrollDistance <= totalScrollDistance) {
      carouselRef.current?.scrollTo(
        carouselRef.current?.scrollLeft + scrollDistance,
        0,
      );
    } else {
      carouselRef.current?.scrollTo(totalScrollDistance, 0);
    }
  };

  const onScroll = () => {
    if (typeof carouselRef.current?.scrollLeft === 'number') {
      setPositionStatuses({
        isAtStartPosition: carouselRef.current?.scrollLeft === 0,
        isAtEndPosition: carouselRef.current?.scrollLeft >= totalScrollDistance,
      });
    }
  };

  return {
    moveLeft,
    moveRight,
    onScroll,
    carouselRef,
    isAtStartPosition: positionStatuses.isAtStartPosition,
    isAtEndPosition: positionStatuses.isAtEndPosition,
  };
}

export { useCarousel };
