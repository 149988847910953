import { createContext, useContext } from 'react';

import { LEVELS } from 'features/location/Location.typed';
import type {
  ILocationContext,
  LocationProviderProps,
} from 'features/location/Location.typed';
import { DEFAULT_COORDINATES } from 'features/location/Location.constants';
import { useLocationReducer } from 'features/location/Location.reducer';

const LocationContext = createContext<ILocationContext>({
  isLoading: false,
  setIsLoading: () => null,
  isExpanded: false,
  setIsExpanded: () => null,
  isLocationBottomSheetOpen: false,
  setIsLocationBottomSheetOpen: () => null,
  searchInput: '',
  setSearchInput: () => null,
  locationDisplay: '',
  setLocationDisplay: () => null,
  areas: [],
  setAreas: () => null,
  coordinates: DEFAULT_COORDINATES,
  setCoordinates: () => null,
  radius: '',
  setRadius: () => null,
  selectedCounty: null,
  setSelectedCounty: () => null,
  level: LEVELS.LEVEL_ONE,
  setLevel: () => null,
  countyTown: null,
  setCountyTown: () => null,
  levelOneReset: () => null,
  levelTwoReset: () => null,
  setAutocompleteOptions: () => null,
  setCountyAreas: () => null,
  countyAreaSelect: () => null,
  handleClose: () => null,
  resetToDefault: () => null,
  countyList: [],
  radiusList: [],
  page: null,
  closeDisplay: () => null,
});

function LocationProvider(props: LocationProviderProps) {
  const {
    initialSelectedCounty = null,
    initialRadius = null,
    initialLocationDisplay = '',
    initialCountyTown = null,
    initialCoordinates = DEFAULT_COORDINATES,
    countyList,
    radiusList,
    page,
    children,
  } = props;
  const { ...values } = useLocationReducer({
    initialSelectedCounty,
    initialRadius,
    initialCountyTown,
    initialCoordinates,
    initialLocationDisplay,
    countyList,
    radiusList,
  });

  return (
    <LocationContext.Provider
      value={{
        ...values,
        countyList,
        radiusList,
        page,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

const useLocationContext = () => useContext(LocationContext);

export { LocationProvider, useLocationContext };
