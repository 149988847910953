import { counties } from 'helpers/constants';
import type { Coordinates } from 'features/location/Location.typed';

const DEFAULT_PRESELECT = {
  value: '',
  displayName: 'All Ireland',
  formattedDisplayName: '<b>All Ireland</b>',
};
const GROUP_NAME = 'areas';
const SEARCH_INPUT_PLACEHOLDER = 'Type town, area or county';
const UPDATED_LOCATIONS = [DEFAULT_PRESELECT, ...counties];
const RADIUS_OPTIONS = [
  {
    value: '5',
    label: '+5km',
  },
  {
    value: '10',
    label: '+10km',
  },
  {
    value: '25',
    label: '+25km',
  },
  {
    value: '50',
    label: '+50km',
  },
  {
    value: '100',
    label: '+100km',
  },
  {
    value: '250',
    label: '+250km',
  },
  {
    value: '500',
    label: '+500km',
  },
];

const DEFAULT_COORDINATES: Coordinates = {
  latitude: '',
  longitude: '',
};

const DEFAULT_RADIUS = '5';
const DEFAULT_COUNTY_TOWN_RADIUS = '25';

const MOBILE_SKELETON_LIST_LENGTH = 16;
const DESKTOP_SKELETON_LIST_LENGTH = 12;
const BOTTOM_SHEET_HEIGHT = '75vh';

export {
  DEFAULT_PRESELECT,
  GROUP_NAME,
  SEARCH_INPUT_PLACEHOLDER,
  UPDATED_LOCATIONS,
  RADIUS_OPTIONS,
  DEFAULT_COORDINATES,
  DEFAULT_RADIUS,
  DEFAULT_COUNTY_TOWN_RADIUS,
  MOBILE_SKELETON_LIST_LENGTH,
  DESKTOP_SKELETON_LIST_LENGTH,
  BOTTOM_SHEET_HEIGHT,
};
