import styled from 'styled-components';
import { media } from 'helpers/breakpoints';
import { Container as ValuationToolFieldsContainer } from 'components/CarValuationPage/components/ValuationTool/ValuationToolFields';

const ValuationLookupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.M16};
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  padding: ${({ theme }) => theme.spacing.M16};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
  border-radius: ${({ theme }) => theme.borderRadius.default};

  ${media.large} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.M32};
    padding: ${({ theme }) => theme.spacing.M24};
    margin: ${({ theme }) => theme.spacing.M24} 0;
  }
`;

const PartnershipIntro = styled.p`
  ${({ theme }) => theme.fontSize.L18}
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const Partnership = styled.div`
  display: flex;
  p {
    ${({ theme }) => theme.fontSize.M14}
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    margin-right: ${({ theme }) => theme.spacing.S4};
  }
`;

const ValuationToolFieldContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.S8};

  ${ValuationToolFieldsContainer} {
    margin-bottom: 0;
    width: 100%;
  }

  label {
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    margin-bottom: 0;
  }

  ${media.large} {
    flex-direction: row;
    margin-bottom: 0;
    max-width: 780px;
  }
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-top: auto;
  width: 100%;

  button {
    width: 100%;
  }

  ${media.large} {
    margin-top: auto;
    margin-left: ${({ theme }) => theme.spacing.M16};
    width: unset;
    button {
      width: 188px;
    }
  }
`;

export {
  ValuationLookupWrapper,
  PartnershipIntro,
  Partnership,
  ValuationToolFieldContainer,
  SubmitButtonWrapper,
};
