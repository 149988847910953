import styled from 'styled-components';
import type { ReactNode } from 'react';

import { media } from 'helpers/breakpoints';

export type Tab<TabValue> = {
  value: TabValue;
  label: string | ReactNode;
  isDisabled?: boolean;
};
export interface TabSelectorProps<TabValue = string> {
  tabs: Tab<TabValue>[];
  selectedTab: TabValue;
  onSelectTab: (tab: TabValue) => void;
  className?: string;
  variant?: boolean;
}

interface ITab {
  selected: boolean;
  variant?: boolean;
  isDisabled?: boolean;
}

interface ISlider {
  index: number;
  tabCount: number;
  variant?: boolean;
}

const STabSelector = styled.div`
  position: relative;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Tab = styled.button<ITab>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S8} 0;
  margin: 0;
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme, variant, isDisabled }) =>
    isDisabled
      ? theme.colors.GREY_LIGHT
      : variant
      ? theme.colors.GREY_DARK
      : theme.colors.WHITE};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  text-transform: capitalize;

  & + & {
    margin: 0 0 0 ${({ theme }) => theme.spacing.M24};
  }

  ${({ selected, theme }) =>
    selected && `font-weight: ${theme.fontWeight.bold};`}

  ${({ selected, variant, theme }) =>
    selected && variant && `color: ${theme.colors.GREY_DARKER};`}

  ${media.smallOnly} {
    ${({ theme }) => theme.fontSize.M16};

    & + & {
      margin: 0 0 0 ${({ theme }) => theme.spacing.S8};
    }
  }
`;

const Slider = styled.span<ISlider>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(
    (100% - ${({ tabCount }) => (tabCount - 1) * 24}px) /
      ${({ tabCount }) => tabCount}
  );
  height: 2px;
  background: ${({ theme, variant }) =>
    variant ? theme.colors.GREY_DARKER : theme.colors.WHITE};
  transform: translateX(
    calc((100% * ${({ index }) => index}) + ${({ index }) => index * 24}px)
  );
  transition: all 0.2s ease-in-out;

  ${media.smallOnly} {
    width: calc(
      (100% - ${({ tabCount }) => (tabCount - 1) * 8}px) /
        ${({ tabCount }) => tabCount}
    );
    transform: translateX(
      calc((100% * ${({ index }) => index}) + ${({ index }) => index * 8}px)
    );
  }
`;

function TabSelector<TabValue extends string = string>({
  tabs,
  selectedTab,
  onSelectTab,
  className,
  variant,
}: TabSelectorProps<TabValue>) {
  const selectedIndex = tabs.findIndex((tab) => tab.value === selectedTab);

  return (
    <STabSelector className={className} data-testid="tab-selector">
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            data-testid={tab.value + '-tab'}
            key={index}
            selected={selectedIndex === index}
            onClick={() => onSelectTab(tab.value)}
            variant={variant}
            disabled={tab.isDisabled}
            isDisabled={tab.isDisabled}
          >
            {tab.label}
          </Tab>
        ))}
      </Tabs>
      <Slider index={selectedIndex} tabCount={tabs.length} variant={variant} />
    </STabSelector>
  );
}

export { TabSelector };
