import { InternalLinks } from 'components/Toolkit/InternalLinks/InternalLinks';
import * as Styled from 'components/HomePage/styles/HomePage.styled';
import type { PopularSearchesProps } from 'features/landing/PopularSearches/PopularSearches.typed';

const PopularSearches = (props: PopularSearchesProps) => {
  const { seoLinks } = props;
  return (
    <Styled.WhiteSection>
      {seoLinks?.internalLinks && seoLinks.internalLinks.length !== 0 && (
        <div className="row small-collapse medium-uncollapse">
          <div className="columns small-12">
            <Styled.Container>
              <Styled.SectionHeader>
                <Styled.Heading>Popular Searches</Styled.Heading>
              </Styled.SectionHeader>
              <InternalLinks links={seoLinks.internalLinks} />
            </Styled.Container>
          </div>
        </div>
      )}
    </Styled.WhiteSection>
  );
};

export { PopularSearches };
