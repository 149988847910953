import { useReducer } from 'react';

import {
  ACTION_TYPE,
  LEVELS,
  UseLocationReducerProps,
} from 'features/location/Location.typed';
import type {
  ActionType,
  StateType,
  Coordinates,
} from 'features/location/Location.typed';
import {
  DEFAULT_COORDINATES,
  DEFAULT_RADIUS,
  DEFAULT_COUNTY_TOWN_RADIUS,
} from 'features/location/Location.constants';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { findRadiusByValue } from 'features/location/helpers';

const locationReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case ACTION_TYPE.SET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    case ACTION_TYPE.SET_SELECTED_COUNTY:
      return {
        ...state,
        selectedCounty: action.payload,
      };
    case ACTION_TYPE.SET_LOCATION_DISPLAY:
      return {
        ...state,
        locationDisplay: action.payload,
      };
    case ACTION_TYPE.SET_IS_EXPANDED:
      return {
        ...state,
        isExpanded: action.payload,
      };
    case ACTION_TYPE.SET_SEARCH_INPUT:
      return {
        ...state,
        searchInput: action.payload,
      };
    case ACTION_TYPE.SET_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case ACTION_TYPE.SET_IS_LOCATION_BOTTOM_SHEET_OPEN:
      return {
        ...state,
        isLocationBottomSheetOpen: action.payload,
      };
    case ACTION_TYPE.SET_COORDINATES:
      return {
        ...state,
        coordinates: action.payload,
      };
    case ACTION_TYPE.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTION_TYPE.SET_LEVEL:
      return {
        ...state,
        level: action.payload,
      };
    case ACTION_TYPE.SET_COUNTY_TOWN:
      return {
        ...state,
        countyTown: action.payload,
      };
    case ACTION_TYPE.LEVEL_ONE_RESET:
      return {
        ...state,
        selectedCounty: null,
        locationDisplay: '',
        searchInput: '',
        areas: action.payload.areas,
        radius: action.payload.radius,
        coordinates: DEFAULT_COORDINATES,
        isExpanded: false,
        isLocationBottomSheetOpen: false,
        countyTown: null,
      };
    case ACTION_TYPE.LEVEL_TWO_RESET:
      return {
        ...state,
        areas: action.payload,
        coordinates: DEFAULT_COORDINATES,
        searchInput: '',
        level: LEVELS.LEVEL_ONE,
        countyTown: null,
        locationDisplay: state.selectedCounty?.displayName || '',
      };
    case ACTION_TYPE.SET_COUNTY_AREAS:
      return {
        ...state,
        coordinates: action.payload.countyTown
          ? state.coordinates
          : DEFAULT_COORDINATES,
        selectedCounty: action.payload.selectedCounty,
        locationDisplay: action.payload.locationDisplay,
        level: LEVELS.LEVEL_TWO,
        isLoading: false,
        areas: action.payload.areas,
        radius: action.payload.countyTown
          ? state.radius
          : action.payload.radius,
        countyTown: action.payload.countyTown,
      };
    case ACTION_TYPE.COUNTY_AREA_SELECT:
      return {
        ...state,
        locationDisplay: action.payload.locationDisplay,
        coordinates: action.payload.coordinates,
        isExpanded: false,
        isLocationBottomSheetOpen: false,
        radius: action.payload.radius,
        selectedCounty: action.payload.selectedCounty,
        countyTown: action.payload.countyTown,
      };
    case ACTION_TYPE.HANDLE_CLOSE:
      return {
        ...state,
        areas: action.payload,
        searchInput: '',
        level: LEVELS.LEVEL_ONE,
      };
    case ACTION_TYPE.SET_AUTOCOMPLETE_OPTIONS:
      return {
        ...state,
        areas: action.payload,
        level: LEVELS.LEVEL_TWO,
        isLoading: false,
      };
    case ACTION_TYPE.RESET_TO_DEFAULT:
      return {
        ...initialState,
        areas: action.payload.areas,
        radius: action.payload.radius,
      };
    case ACTION_TYPE.CLOSE_DISPLAY:
      return {
        ...state,
        isExpanded: false,
        isLocationBottomSheetOpen: false,
      };
    default:
      return state;
  }
};

const initialState: StateType = {
  isExpanded: false,
  searchInput: '',
  isLocationBottomSheetOpen: false,
  coordinates: DEFAULT_COORDINATES,
  level: LEVELS.LEVEL_ONE,
  isLoading: false,
  areas: [],
  locationDisplay: '',
  selectedCounty: null,
  countyTown: null,
  radius: '',
};

const useLocationReducer = (props: UseLocationReducerProps) => {
  const {
    initialSelectedCounty,
    initialLocationDisplay,
    initialCoordinates,
    initialCountyTown,
    initialRadius,
    countyList,
    radiusList,
  } = props;

  const defaultRadius = findRadiusByValue(DEFAULT_RADIUS, radiusList);

  const [state, dispatch] = useReducer(locationReducer, {
    ...initialState,
    selectedCounty: initialSelectedCounty,
    locationDisplay: initialLocationDisplay,
    coordinates: initialCoordinates,
    countyTown: initialCountyTown,
    radius: initialRadius ?? defaultRadius,
    areas: countyList,
  });

  const setIsLoading = (isLoading: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_LOADING,
      payload: isLoading,
    });

  const setIsExpanded = (isExpanded: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_EXPANDED,
      payload: isExpanded,
    });

  const setIsLocationBottomSheetOpen = (isLocationBottomSheetOpen: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_LOCATION_BOTTOM_SHEET_OPEN,
      payload: isLocationBottomSheetOpen,
    });

  const setSearchInput = (searchInput: string) =>
    dispatch({
      type: ACTION_TYPE.SET_SEARCH_INPUT,
      payload: searchInput,
    });

  const setLocationDisplay = (locationDisplay: string) =>
    dispatch({
      type: ACTION_TYPE.SET_LOCATION_DISPLAY,
      payload: locationDisplay,
    });

  const setAreas = (areas: ListItem[]) =>
    dispatch({
      type: ACTION_TYPE.SET_AREAS,
      payload: areas,
    });

  const setCoordinates = (coordinates: Coordinates) =>
    dispatch({
      type: ACTION_TYPE.SET_COORDINATES,
      payload: coordinates,
    });

  const setRadius = (radius: string) =>
    dispatch({
      type: ACTION_TYPE.SET_RADIUS,
      payload: radius,
    });

  const setSelectedCounty = (selectedCounty: ListItem | null) =>
    dispatch({
      type: ACTION_TYPE.SET_SELECTED_COUNTY,
      payload: selectedCounty,
    });

  const setLevel = (level: LEVELS) =>
    dispatch({
      type: ACTION_TYPE.SET_LEVEL,
      payload: level,
    });

  const setCountyTown = (countyTown: string) =>
    dispatch({
      type: ACTION_TYPE.SET_COUNTY_TOWN,
      payload: countyTown,
    });

  const levelOneReset = () =>
    dispatch({
      type: ACTION_TYPE.LEVEL_ONE_RESET,
      payload: {
        areas: countyList,
        radius: defaultRadius,
      },
    });

  const levelTwoReset = (areas: ListItem[]) =>
    dispatch({
      type: ACTION_TYPE.LEVEL_TWO_RESET,
      payload: areas,
    });

  const setAutocompleteOptions = (areas: ListItem[]) =>
    dispatch({
      type: ACTION_TYPE.SET_AUTOCOMPLETE_OPTIONS,
      payload: areas,
    });

  const setCountyAreas = (
    selectedCounty: ListItem | null,
    locationDisplay: string,
    areas: ListItem[],
    countyTown: string | null,
  ) =>
    dispatch({
      type: ACTION_TYPE.SET_COUNTY_AREAS,
      payload: {
        selectedCounty,
        locationDisplay,
        areas,
        radius: defaultRadius,
        countyTown,
      },
    });

  const countyAreaSelect = (
    locationDisplay: string,
    coordinates: Coordinates,
    selectedCounty: ListItem,
    countyTown: string | null,
    radius?: string,
  ) =>
    dispatch({
      type: ACTION_TYPE.COUNTY_AREA_SELECT,
      payload: {
        locationDisplay,
        coordinates,
        selectedCounty,
        countyTown,
        radius:
          radius ?? findRadiusByValue(DEFAULT_COUNTY_TOWN_RADIUS, radiusList),
      },
    });

  const handleClose = (areas: ListItem[]) =>
    dispatch({
      type: ACTION_TYPE.HANDLE_CLOSE,
      payload: areas,
    });

  const closeDisplay = () =>
    dispatch({
      type: ACTION_TYPE.CLOSE_DISPLAY,
    });

  const resetToDefault = () =>
    dispatch({
      type: ACTION_TYPE.RESET_TO_DEFAULT,
      payload: {
        areas: countyList,
        radius: defaultRadius,
      },
    });

  return {
    isLoading: state.isLoading,
    isExpanded: state.isExpanded,
    isLocationBottomSheetOpen: state.isLocationBottomSheetOpen,
    searchInput: state.searchInput,
    locationDisplay: state.locationDisplay,
    areas: state.areas,
    coordinates: state.coordinates,
    radius: state.radius,
    selectedCounty: state.selectedCounty,
    level: state.level,
    countyTown: state.countyTown,
    setIsLoading,
    setIsExpanded,
    setIsLocationBottomSheetOpen,
    setSearchInput,
    setLocationDisplay,
    setAreas,
    setCoordinates,
    setRadius,
    setSelectedCounty,
    setLevel,
    setCountyTown,
    levelOneReset,
    levelTwoReset,
    setAutocompleteOptions,
    setCountyAreas,
    countyAreaSelect,
    handleClose,
    resetToDefault,
    closeDisplay,
  };
};

export { useLocationReducer };
