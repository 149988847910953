import getConfig from 'next/config';
import type { AxiosResponse } from 'axios';
import type { IncomingHttpHeaders } from 'http';

import {
  donedealGateway,
  donedealServicesGateway,
} from 'api/api-utils/commonApiUtils';

const {
  publicRuntimeConfig: { API_LISTINGS_TIMEOUT },
} = getConfig();

import type {
  AdDetails,
  GetPhoneNumber,
  TrackAdCallNumber,
  StripeCheckoutSessionResponse,
  HomePageResponse,
  ISectionsResponse,
} from 'api/types/adviewApiTypes';

async function getAd(
  id: string,
  query: { [key: string]: any },
  headers?: IncomingHttpHeaders,
): Promise<AxiosResponse<AdDetails>> {
  // To support legacy apollo tracking we need to forward query parameters
  // to the backend. We strip off adId and title as they are not needed.
  const { adId, title, section, ...tidyQuery } = query;
  const forwardParams = `?${new URLSearchParams(tidyQuery).toString()}`;

  // We manually override accept header to avoid errors
  const response: AxiosResponse<AdDetails> = await donedealServicesGateway.get(
    `/ddapi/v1/listings/${id}${forwardParams.length > 1 ? forwardParams : ''}`,
    {
      headers: { ...headers, accept: 'application/json' },
      timeout: parseInt(API_LISTINGS_TIMEOUT),
    },
  );

  return response;
}

async function getLatestAdId(): Promise<AxiosResponse<AdDetails>> {
  const response: AxiosResponse<AdDetails> = await donedealServicesGateway.get(
    '/ddapi/v1/health/listings',
    { timeout: parseInt(API_LISTINGS_TIMEOUT) },
  );

  return response;
}

async function getPhoneNumber(
  id: number,
  captchaToken: string,
  campaignId?: string | null,
): Promise<AxiosResponse<GetPhoneNumber>> {
  const response: AxiosResponse<GetPhoneNumber> = await donedealGateway.get(
    `/ddapi/v1/listings/${id}/phonereveal${
      campaignId && campaignId !== null ? `?campaign=${campaignId}` : ''
    }`,
    {
      headers: { 'Recaptcha-Token': captchaToken, platform: 'web' },
    },
  );
  return response;
}

async function trackAdCallNumber(
  id: number,
  campaignId?: string | null,
): Promise<AxiosResponse<TrackAdCallNumber>> {
  const response: AxiosResponse<TrackAdCallNumber> = await donedealGateway.get(
    `/ddapi/legacy/cadview/api/v3/log/${id}/call${
      campaignId && campaignId !== null ? `?campaign=${campaignId}` : ''
    }`,
  );
  return response;
}

async function createStripeCheckoutSession(
  adId: number,
  selectedQuantity: number,
  headers: IncomingHttpHeaders,
  messageProductId?: string,
  phoneNumber?: string,
): Promise<AxiosResponse<StripeCheckoutSessionResponse>> {
  const response: AxiosResponse<StripeCheckoutSessionResponse> =
    await donedealGateway.post(
      `/ddapi/legacy/cadview/api/v3/stripe/checkout/sessions/${adId}?quantity=${selectedQuantity}${
        messageProductId ? `&messageProductId=${messageProductId}` : ''
      }${phoneNumber ? `&phoneNumber=${phoneNumber}` : ''}`,
      { headers },
    );

  return response;
}

async function getHomePageData(
  focus: 'motor' | 'marketplace' | 'farming',
  headers?: IncomingHttpHeaders,
  query?: { [key: string]: any },
  numberOfAds = 8,
): Promise<AxiosResponse<HomePageResponse>> {
  const forwardParams = `?${new URLSearchParams(query).toString()}`;
  // We manually override accept header to avoid errors
  const config = { headers: { ...headers, accept: 'application/json' } };

  const response: AxiosResponse<HomePageResponse> =
    await donedealServicesGateway.get(
      `/ddapi/v1/homepage/${focus}/${numberOfAds}${
        forwardParams.length > 1 ? forwardParams : ''
      }`,
      config,
    );

  return response;
}

async function getSections(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<ISectionsResponse>> {
  const response: AxiosResponse<ISectionsResponse> =
    await donedealServicesGateway.get(
      `/ddapi/legacy/cadview/api/v5/homepage/sections`,
      { headers },
    );
  return response;
}

export const adViewApi = {
  getAd,
  getPhoneNumber,
  trackAdCallNumber,
  createStripeCheckoutSession,
  getHomePageData,
  getLatestAdId,
  getSections,
};
