import styled from 'styled-components';

import { Card } from 'components/ToolkitV2/CardV2/Card';

import { media } from 'helpers/breakpoints';

const StyledCard = styled(Card)`
  width: 130px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.shadow.CONTAINER};
  overflow: hidden;
  cursor: pointer;

  ${media.xLarge} {
    width: 100%;
  }
`;

export { StyledCard };
