import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

const Heading = styled.h2`
  ${({ theme }) => theme.fontSize.L18};
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.M16};
  overflow: hidden;

  ${media.medium} {
    padding: ${({ theme }) => theme.spacing.M24} 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

const AdGrid = styled.ul`
  overflow-x: scroll;
  display: flex;
  gap: ${({ theme }) => theme.spacing.M16};
  padding: ${({ theme }) => theme.spacing.S2};
`;

export { Container, HeaderContainer, AdGrid, Heading };
