import { ChevronBackOutlineIc, ChevronForwardOutlineIc } from '@dsch/dd-icons';

import { useCarousel } from 'features/carousel/Carousel.hook';

import * as Styled from 'features/carousel/Carousel.styled';

import type { CarouselProps } from 'features/carousel/Carousel.typed';

function Carousel(props: CarouselProps) {
  const { children, scrollDistance, title, className, accessibilityLabel } =
    props;

  const {
    moveLeft,
    moveRight,
    onScroll,
    carouselRef,
    isAtStartPosition,
    isAtEndPosition,
  } = useCarousel({
    scrollDistance,
  });

  return (
    <Styled.Container className={className}>
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
        <Styled.NavigationButtonContainer>
          <Styled.NavigationButton
            size="SMALL"
            disabled={isAtStartPosition}
            onClick={moveLeft}
            aria-labelledby={accessibilityLabel}
          >
            <ChevronBackOutlineIc width="16" height="16" />
          </Styled.NavigationButton>
          <Styled.NavigationButton
            size="SMALL"
            disabled={isAtEndPosition}
            onClick={moveRight}
            aria-labelledby={accessibilityLabel}
          >
            <ChevronForwardOutlineIc width="16" height="16" />
          </Styled.NavigationButton>
        </Styled.NavigationButtonContainer>
      </Styled.Heading>
      <Styled.Carousel
        ref={carouselRef}
        onScroll={onScroll}
        id={accessibilityLabel}
      >
        {children}
      </Styled.Carousel>
    </Styled.Container>
  );
}

export { Carousel };
