import type { FC } from 'react';
import dynamic from 'next/dynamic';

import type { HomePageSearchManagerProps } from 'components/HomePage/Layouts/HomePageSearchManager';
import { HeroImage } from 'components/PageWrappers/SharedComponents/HeroImage/HeroImage';
import type { TabSelectorProps } from 'components/Toolkit/TabSelector/TabSelector';
import type { SiteFocus } from 'components/HomePage/types';
import * as Styled from 'components/HomePage/styles/HomePage.styled';
import * as HOMEPAGE from 'components/HomePage/helpers/constants';
import type { HeroProps } from 'features/landing/Hero/Hero.typed';
import { LocationProvider } from 'features/location/Location.context';
import {
  RADIUS_OPTIONS,
  UPDATED_LOCATIONS,
} from 'features/location/Location.constants';

const HomePageSearchManager = dynamic<HomePageSearchManagerProps>(() =>
  import(
    /* webpackChunkName: "HomePageSearchManager" */ 'components/HomePage/Layouts/HomePageSearchManager'
  ).then((mod) => mod.HomePageSearchManager),
);

const LAST_SEARCH_DATA_TRACKING_VALUE = 'last_search_home_page';

const Hero = (props: HeroProps) => {
  const {
    cdnUrl,
    domain,
    adCount,
    siteFocus,
    setSiteFocus,
    lastSearchData,
    setLastSearch,
    motorSearchFilters,
  } = props;
  return (
    <HeroImage section={siteFocus} cdnUrl={cdnUrl}>
      <div className="row set-width-100">
        <div className="columns small-12">
          <Styled.HeroContainer>
            <Styled.STabSelector<FC<TabSelectorProps<SiteFocus>>>
              onSelectTab={(tab) => setSiteFocus(tab)}
              selectedTab={siteFocus}
              tabs={HOMEPAGE.TABS}
            />
            <LocationProvider
              countyList={UPDATED_LOCATIONS}
              radiusList={RADIUS_OPTIONS}
            >
              <HomePageSearchManager
                domain={domain}
                cdnUrl={cdnUrl}
                focus={siteFocus}
                lastSearchData={lastSearchData}
                setLastSearch={setLastSearch}
                lastSearchDataTrackingValue={LAST_SEARCH_DATA_TRACKING_VALUE}
                adCount={adCount}
                motorSearchFilters={motorSearchFilters}
              />
            </LocationProvider>
          </Styled.HeroContainer>
        </div>
      </div>
    </HeroImage>
  );
};

export { Hero };
