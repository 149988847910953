import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';

const findCountyByValue = (countyList: ListItem[], countyValue?: string) => {
  const match = countyList.find(({ value }) => value === countyValue);
  return match;
};

/** Provides the matching entry in the @parameter radiusList,
 * corresponding to the @parameter radius, in order to display the
 * appropriate default radius.
 *
 * This default may change based on whether only a county is selected
 * or a county is selected in conjunction with a county town */
const findRadiusByValue = (
  radius: string,
  radiusList?: Array<{
    label: string;
    value: string;
  }>,
) => radiusList?.find(({ value }) => value === radius)?.value ?? '';

export { findCountyByValue, findRadiusByValue };
