import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';
import { Price } from 'components/ToolkitV2/CardV2/components//Price/Price';

import type { AdSummary } from 'types';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';

import { StyledCard } from 'features/landing/RecentAds/RecentAdsCard.styled';
import { BasicHeader } from 'components/ToolkitV2/CardV2/components/BasicHeader/BasicHeader';

const RecentAdsCard = (props: AdSummary) => {
  const {
    header,
    imageAlt,
    photos,
    currency,
    price,
    oldPriceView,
    financeSummary,
    priceOnRequest,
  } = props;

  const oldPrice = oldPriceView?.showOldPrice ? oldPriceView.oldPrice : '';

  return (
    <StyledCard
      orientationSmall={ORIENTATION.VERTICAL}
      header={
        <BasicHeader
          title={header}
          titleSmallFontSize="B12"
          titleMediumFontSize="B12"
        />
      }
      paddingSmall="S4"
      gapSmall="S4"
      image={
        <Image
          alt={imageAlt}
          orientationSmall={ORIENTATION.VERTICAL}
          src={photos?.[0].small2}
          imgSrcWebp={photos?.[0].small2Webp}
        />
      }
      footer={
        <Price
          currency={currency}
          price={price}
          oldPrice={oldPrice}
          pricePerMonth={financeSummary?.price}
          priceOnRequest={priceOnRequest}
          isGrid
          displayLabels={false}
          priceSmallFontSize="B12"
          priceMediumFontSize="B12"
          gapMedium="S8"
          priceDropMediumFontSize="B10"
        />
      }
    />
  );
};

export { RecentAdsCard };
