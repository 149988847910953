import { Form } from 'react-final-form';

import { useHomePageValuationLookup } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.hook';
import type { ValuationLookupFormValues } from 'features/landing/HomePageValuationLookup/HomePageValuationLookup.typed';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { ValuationLookupForm } from 'features/landing/HomePageValuationLookup/ValuationLookupForm/ValuationLookupForm';

const HomePageValuationLookup = () => {
  const { accessToken } = useAuthOptions();
  const { onSubmit } = useHomePageValuationLookup({ accessToken });

  return (
    <Form<ValuationLookupFormValues>
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <ValuationLookupForm />
        </form>
      )}
    />
  );
};

export { HomePageValuationLookup };
